.heigth100 {height: calc(100vh - 55px); overflow: hidden;}
.box-base { width: 100%;display: flex; align-items: center; justify-content: center; top: 0px;left: 0px; height: 100%; border-top-left-radius: 20px; background-color: #fff}
.container-100 {
    max-width: 100% !important;
}
.no-padding-hrz {padding-left: 0px !important; padding-right: 0px !important;}
#content-tab {
    background-color: #ffffff;
    border-top-left-radius: 20px;
    z-index:1050;
    >div {
        margin: 20px 20px 0px 20px;
        >div {
            width: auto;
        }
    }
}
.content-heading {margin: 0px !important; padding: 20px 0px 0px !important; background-color: transparent !important; background-image: none !important; border: 0 !important}
.navbar {padding : 0px !important}
.no-border {border:0 !important; box-shadow: none !important}
button {height :35px}
.grid-plan {justify-content:center; vertical-align: middle; display:flex; gap:5px; height: auto !important}
.content-box-cart { display:flex; flex-direction:row}
.content-box-cart-page { display:flex; flex-direction:row}
.box-card {width: 100%; min-height: auto; height: 100%;}
.content-wrapper {padding: 10px 0px 0px 0px !important; min-height: auto; height: 100%; border: 0 !important;  overflow-y: auto; overflow-x: hidden; scrollbar-face-color: #ff8c00;}
.content-wrapper-bottom {height: 70vh !important;}
.content-wrapper-bottom81 {height: 81vh !important;}
.content-wrapper-bottom100 {height: 100vh !important;}
.rodape-paginate > ul> li > a{ color: #fff !important; }

.disabled > a{ color:#e9e9e9 !important; cursor:default;}
.disabled > a:hover{ text-decoration: none;}
.form-control {border-radius: 5px !important; width: 100% !important;}
.form-control:invalid {border-radius: 5px !important}
.invalid-feedback{margin-top: -3px !important;}
select, .form-control, .is-valid{background-position : right calc(0.75em + 0.3875rem) center !important}
.box-maintance-buttons {display: flex; padding: 3px;align-items: center;}
.box-maintance-buttons-aux {display:flex; gap:10px;border-left:1px dashed #cacaca;flex-wrap: wrap;padding: 0px 5px}
.footer-fixed {position: fixed !important; bottom: 0 !important; width: 100%;}
.width150 {width: 150px !important;}
.width300 {width: 300px !important;}
.width350 {width: 350px !important;max-width: 300px !important;min-width: 300px !important;}
.width400 {width: 400px !important;}
.width450 {width: 450px !important;}
.width500 {width: 500px !important;}
.width550 {width: 550px !important; max-width: 550px !important;min-width: 550px !important;}
.width600 {width: 600px !important; max-width: 600px !important;min-width: 600px !important;}
.width650 {width: 650px !important; max-width: 650px !important;min-width: 650px !important;}
.width700 {width: 700px !important; min-width: 700px !important}
.width775 {width: 775px !important; min-width: 775px !important}
.width800 {width: 800px !important; min-width: 800px !important}
.width900 {width: 900px !important; min-width: 900px !important}
.width1000 {width: 1000px !important; min-width: 1000px !important}
.width1200 {width: 1200px !important; min-width: 1200px !important}
.height90 { height: 90vh;}
.height85 { height: 85vh;}
.font-size-16 {font-size:16px}
.register-form {
    min-width: 365px;
}

.box-large-button {height: 72vh !important}
.item-params {display:flex; height: 25px; cursor:pointer;
    div {width:160px;
        span {font-weight: bold};    
    }
}
@media (max-height: 1024px) {
    .box-large-button {height: 72vh !important}
}

@media (max-height: 937px) {
    .box-large-button {height: 69vh !important}
}

@media (max-height: 841px) {
    .box-large-button {height: 62vh !important}
}

@media (max-height: 820px) {
    .box-large-button {height: 52vh !important}
}

@media (max-height: 768px) {
    .content-box-cart { height: 74vh;}
    .content-box-cart-page {height: 68vh;} 
    .content-box-cart { height: 69vh;}
    .content-wrapper-bottom81 {height: 70vh !important;}
    .nav-floating {max-height: 75vh !important;}
    .box-large-button {height: 59vh !important}
    .box-base {border-radius: 0px;}
 }

 
@media (max-width: 480px) {
    .box-base {border-radius: 0px;}
    #content-tab {border-radius: 0px;}
    .box-maintance-buttons {flex-direction: column!important;align-items: flex-start;}
    .box-maintance-buttons-aux {margin-left: 0px; margin-top: 5px; border-left: 0px; padding-left: 0px!important;}
 }

 @media (max-width: 700px) {

    .content-heading {flex-direction: column !important; align-items: flex-start !important;}
    
 }


 