
body {
    font-family : $font-family !important;
    font-size   : $font-size !important;
    color       : $text-commom-color !important;

}
#closeInteraction {width: 75px !important;}
.alert-55 {margin-bottom: 0px !important; border-bottom-left-radius: 0px !important; border-top-left-radius: 0px !important;}
.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after { border:solid #495057 !important; border-width: 0 2px 2px 0 !important; display: inline-block !important; padding: 2.5px !important; transform: rotate(-135deg) !important; -webkit-transform: rotate(-135deg)!important}
.ReactFlagsSelect-module_selectBtn__19wW7:after {border:solid #495057 !important; border-width: 0 2px 2px 0 !important; display: inline-block !important; padding: 2.5px !important; transform: rotate(45deg) !important; -webkit-transform: rotate(45deg)!important}
.containt-combo-flag { padding-bottom: 0px !important;}
.combo-flag { background-color: #fff !important; padding: 5px; height: 32px !important; border-radius: 6px !important;}

.box-settings-date {display:flex; align-items: center;width: 14.2%; flex-direction: column;}
.box-settings-date:nth-child(n+1) {margin-left: 5px}

.text-default {
    font-family : $font-family !important;
    font-size   : $font-size !important;
    color       : $text-commom-color !important;
}
.btn-suporte {border:0px !important;background: transparent !important;
    &:focus {border:0px !important;background: transparent !important; box-shadow: none!important;;}
    &:hover {border:0px !important;background: transparent !important; box-shadow: none!important;;}}

.custom-toogle {text-decoration: none !important; color: #fff !important; width: 35px !important; display:flex}

.button-close {color       : $text-commom-color !important;
               position    : absolute;
               cursor: pointer;
               right: 5px !important;
               top: 0;
               padding : 5px}

::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */ 
    height: 6px;           /* width of the entire scrollbar */ 
}
::-webkit-scrollbar-track {
    background: $theme-color-disabled;        /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
    background-color: $theme-color;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}


.cursor-wait{cursor: wait !important;}

div {
  scrollbar-width: thin;          /* "auto" or "thin"  */
  scrollbar-color: $theme-color $theme-color-disabled;
}


label {margin-bottom: 0px !important;}
form {width: 100% !important;}
.input-group { flex-direction: column !important;}
.text-theme {color: $theme-color }
.table > thead > tr > th {color: #fff !important;            background-color: $theme-color-opaque !important}
.table th {padding: 0.55rem !important}
.table td {padding: 0.35rem !important; line-height: 25px !important;}
.box-matricula{display: flex; flex-direction: column; border-radius: 5px; padding: 5px 10px;text-align: center}
.box-matricula > label {font-weight: bold}
.box-matricula > span {font-weight: bold; font-size: 20px;}

.box-min-warning{
    background-color: #ffc107;
    color: #856404;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.flex-align-center {align-items: center;}

.btn-sm {height: 29px !important; width: 29px!important ;}

.box-not-register {display: flex; justify-content: center; padding: 20px; font-size: 18px; border-radius: 5px;margin-top: 15px;}

.h4 {font-size : $font-h4 !important}

.bg-theme        { background-color: $theme-color; color: #fff}

.btn, 
.form-control {
    font-size : $font-size !important;
    min-height: calc(1.5em + 0.60rem + 2px) !important;
    // margin-bottom: $distance-elements !important
}


.open { font-weight: 900!important; font-style: normal;}


.mnp-name { 
    margin-bottom   : 5px;
    font-weight     : 600;
    text-decoration : none;
    //color           :$text-commom-color !important;
    color           :#ffffff !important;
    font-size       : 0.9rem;
    &:hover {
        text-decoration : none !important;
        //color           :$theme-color-hover !important;
        color           :#c9c9c9 !important;
    }
}

.profile-wrap {
    padding: 0px 0px 5px 0px;
    background-color: $theme-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 20px;
}

.sidebar-nav {font-size: $font-size  !important;}

.aside-collapsed {
    .sidebar-nav {margin-top: 0px;}
}

.aside-collapsed {
    .profile-wrap {display: none; }
}

.mnp-desc{
    color : #ffffff;  
}

.profile-desc {
    text-align: center;
    width: 175px;
    margin-left: 0px;
}

.dropdown-item{
    font-size: $font-size !important;
    padding: 5px 10px 5px 25px !important;
    &:hover {
        text-decoration : none !important;
        color           :$theme-color !important;
        background-color: $hover !important;
    } 
}

.thead-theme {  color: #fff !important; background-color: $theme-color-opaque !important;
    .head-th {
        td {color:#fff !important;
            background-color: $theme-color-opaque !important}
        }
    }
.thead-light {  color: #000; background-color: #f6f7f8 !important;
    .head-th {
        th {color:#000 !important;
            background-color: $theme-color-opaque !important}
    }
}
.rodape-paginate {
    display: flex; 
    padding: 5px; 
    justify-content: center; 
    margin-top: 15px;  
    width: 100%;
    >ul {
        >li {
            border-radius : 5px; 
            background-color: $theme-color-opaque; 
            padding:5px;
            width: 32px; 
            height: 32px; 
            text-align: center; 
            margin-left: 3px;}
    }
}

.disabled{ color:#e9e9e9 !important; cursor:default}

.modal-header {color : #fff; border:0px !important; background-color: $theme-color-opaque;}
.modal {display: flex !important; align-items: center !important; justify-content: center !important;}
.modal-content {border: 0px !important}
.close {color : #fff !important; background: transparent;border: 0;
    span {font-size: 22px;}
}

.background {
    background: url('./../../../assets/bg.png') no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    //opacity: .4;
    position: absolute;
    display:flex;
    justify-content: center;
    align-items: center;
}


@media only screen and  (max-width: 415px)  {
    .background {
        background-size: auto;
    }
    .profile-wrap {
        background-image: linear-gradient(to right, $theme-color    0%, $theme-medium-decrade 100%);
    }

    .wrapper {
        background-image: linear-gradient(to right, $theme-color    0%, $theme-color-end 100%);
        .section-container {
            background-color: $theme-medium-decrade !important;
        }
    }
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.imgWidth {

    width: 250px !important;
}

.btn-purple {
    background-color: #b100cd !important;
    border-color: #b100cd !important;
    color:#fff !important;
}

.btn-purple:hover {
    background-color: #be2ed6 !important;
    border-color: #be2ed6 !important;
}

.btn-theme {
    background-color: $theme-color  !important;
    border-color: $theme-color !important;
    color:#fff !important;
}

.btn-theme:hover {
    background-color: $theme-color-hover !important;
    border-color: $theme-color-hover !important;
}

.text-twitter {color : rgba(29,161,242,1.00); font-size: 30px; }
.text-twitter:hover{opacity: 1; -webkit-transform: scale(1.15);  transform: scale(1.15);}
.text-facebook {color : #4267b2; font-size: 30px; }
.text-facebook:hover{opacity: 1; -webkit-transform: scale(1.15);  transform: scale(1.15);}
.text-linkedin {color : #0077B5; font-size: 30px; }
.text-linkedin:hover{opacity: 1; -webkit-transform: scale(1.15);  transform: scale(1.15);}

.text-instagram {
    color: transparent;
  background: -webkit-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -o-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
     font-size: 30px; }
.text-instagram:hover{opacity: 1; -webkit-transform: scale(1.15);  transform: scale(1.15);}     
.border-dotted { border-top : thin dotted rgba(0, 0, 0, 0.1); margin-top: 1rem; margin-bottom: 1rem;}
.wd-100 {width: 100%;}



.box-profile-center {width: 550px !important; margin-top: 45px !important;}
.edit-photo-profile {margin-left: -15px; margin-top: 43px; font-size: 20px; color: $theme-color; cursor:pointer}
.edit-photo-profile:hover {color: $theme-color-hover;}


.table-striped tbody tr:nth-of-type(odd){background-color: #f2f9fc !important;}
.table th, .table td {border-top: 0 !important; }

.modal-lg {min-width: 800px !important;}

.box-sem-dados {padding: 15px; display: flex; justify-content: center; border-radius: 5px;}
.pdf-viewer {}
.li-company-title {display: flex !important; align-items: center; width: 100%;}
.handlerStyles {position: absolute;top: 0; left: 0;width: 10px; height: 100%;background: $theme-color-hover; cursor: pointer  };

.height350 {
    .modal-content {
        height: 350px;;
    }
    .modal-body {  
        height: 238px;
        max-height: 238px;
        min-height: 238px;
        overflow: hidden auto;
    }
}

.height400 {
    .modal-content {
        height: 400px;;
    }
    .modal-body {  
        height: 288px;
        max-height: 288px;
        min-height: 288px;
        overflow: hidden auto;
    }
}

.height450 {
    .modal-content {
        height: 450px;;
    }
    .modal-body {  
        height: 338px;
        max-height: 338px;
        min-height: 338px;
        overflow: hidden auto;
    }
}

.height500 {
    .modal-content {
        height: 500px;;
    }
    .modal-body {  
        height: 376px;
        max-height: 376px;
        min-height: 376px;
        overflow: hidden auto;
    }
}

.height525 {
    .modal-content {
        height: 525px;;
    }
    .modal-body {  
        height: 400px;
        max-height: 400px;
        min-height: 400px;
        overflow: hidden auto;
    }
}

.height550 {
    .modal-content {
        height: 550px;
    }
    .modal-body {  
        height: 426px;
        max-height: 426px;
        min-height: 426px;
        overflow: hidden auto;
    }
}

.height600 {
    .modal-content {
        height: 600px;;
    }
    .modal-body {  
        height: 477px;
        max-height: 477px;
        min-height: 477px;
        overflow: hidden auto;
    }
}

.height650 {
    .modal-content {
        height: 650px;;
    }
    .modal-body {  
        height: 527px;
        max-height: 527px;
        min-height: 527px;
        overflow: hidden auto;
    }
}

.height700 {
    .modal-content {
        height: 700px;;
    }
    .modal-body {  
        height: 577px;
        max-height: 577px;
        min-height: 577px;
        overflow: hidden auto;
    }
}

.height750 {
    .modal-content {
        height: 750px;;
    }
    .modal-body {  
        height: 627px;
        max-height: 627px;
        min-height: 627px;
        overflow: hidden auto;
    }
}

.maxHeight350 {
    max-height: 350px;
}
.maxHeight500 {
    max-height: 600px;
}
.maxHeight600 {
    max-height: 600px;
}

.width75 {width:75%}
.padding0 {padding:0px!important}
.marginLeft-1{margin-left: -1px;}

.search-invalid {
    .select-search {
        border:0
    }
    .css-yk16xz-control {
        border-color: #dc3545 !important;
        padding-right: calc(1.5em + 0.75rem) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat !important;
        background-position: right calc(0.375em + 0.995rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
        box-shadow: none;
    }
    .invalid-feedback {
        margin-top: 2px !important;
        display: block !important;
    }
}

.search-valid {
    .select-search {
        border:0
    }
    .css-yk16xz-control {
        border-color: #28a745 !important;
        padding-right: calc(1.5em + 0.75rem) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat !important;
        background-position: right calc(0.375em + 0.995rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
        box-shadow: none;
    }
}

.btn-ligth-gray {
    color: #575757 !important;
    background: #f7f7fa !important;
    transition: color .2s linear,background-color .3s linear !important;
    border:none !important;
}
.btn-ligth-gray:hover {
    color: #575757 !important;
    background-color: #e5e5ea !important;
}

.divider {border-top: 1px dashed $border-line-color; width: 100%; margin-top: 15px;}

.list{width: 20px; height: 20px; background-color: #fff;border-radius: 50%; margin-right: 5px; font-weight: bold; display: flex; align-items: center; justify-content: center;}
